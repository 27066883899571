import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import TIMEFRAMES from '../timeframes';
import CATEGORIES from '../categories';
import LOCATIONS from '../locations';
import TRANSPORT from '../transport';
import { Link } from 'gatsby';

const Filters = ({
  category,
  subcategory,
  timeframe,
  transit,
  categories,
  subcategories,
  filterResults,
  numResults,
  buildPath,
  hasArticles,
  isMobile,
  handleFilterToggle,
}) => {
  const [selectedTimeFrame, setTimeFrame] = useState(timeframe);
  const [selectedLocation, setLocation] = useState(subcategory);
  const [selectedCategory, setCategory] = useState(category);
  const [selectedTransit, setTransit] = useState(transit);
  const [checkedAll, setCheckedAll] = useState({
    allTimeFrames: true,
    allLocations: true,
    allCategories: true,
    allTransit: true,
  });

  const [filterApplied, setFilterApplied] = useState(false);

  const url = typeof window !== 'undefined' ? window.location.href : '';
  // const resultsFor = `${numResults} result${numResults === 1 ? '' : 's'} for "`;

  const closeFilter = filterType => {
    document.getElementById(filterType).removeAttribute('open');
  };

  const handleCheckedAll = type => {
    switch (type) {
      case 'allTimeFrames':
        setTimeFrame(null);
        !isMobile && handleBuildPath();
        return setCheckedAll(prevState => ({
          ...prevState,
          allTimeFrames: true,
        }));
      case 'allLocations':
        setLocation(null);
        !isMobile && handleBuildPath();
        return setCheckedAll(prevState => ({
          ...prevState,
          allLocations: true,
        }));
      case 'allCategories':
        setCategory(null);
        !isMobile && handleBuildPath();
        return setCheckedAll(prevState => ({
          ...prevState,
          allCategories: true,
        }));
      case 'allTransit':
        setTransit(null);
        !isMobile && handleBuildPath();
        return setCheckedAll(prevState => ({
          ...prevState,
          allTransit: true,
        }));
      default:
    }
  };

  const handleBuildPath = () => {
    !hasArticles
      ? navigate(
          buildPath(
            selectedCategory,
            selectedLocation,
            selectedTimeFrame,
            selectedTransit
          )
        )
      : navigate(
          buildPath(selectedCategory, selectedLocation, selectedTransit)
        );
  };

  // const handleClearMobile = () => {
  //   setTimeFrame(null);
  //   setCategory(null);
  //   setLocation(null);
  //   setTransit(null);
  // };

  // const handleClear = () => {
  //   !hasArticles
  //     ? navigate(buildPath(null, null, null, null))
  //     : navigate(buildPath(category.slug, null));
  //   setFilterApplied(false);
  // };

  useEffect(() => {
    if (selectedTimeFrame || selectedTimeFrame === null) {
      setCheckedAll(prevState => ({
        ...prevState,
        allTimeFrames: false,
      }));
      !isMobile && handleBuildPath();
    }
    if (selectedLocation || selectedLocation === null) {
      setCheckedAll(prevState => ({
        ...prevState,
        allLocations: false,
      }));
      !isMobile && handleBuildPath();
    }
    if (selectedCategory || selectedCategory === null) {
      setCheckedAll(prevState => ({
        ...prevState,
        allCategories: false,
      }));
      !isMobile && handleBuildPath();
    }
    if (selectedTransit || selectedTransit === null) {
      !isMobile && handleBuildPath();
      setCheckedAll(prevState => ({
        ...prevState,
        allTransit: false,
      }));
    }
  }, [selectedTimeFrame, selectedLocation, selectedCategory, selectedTransit]);

  useEffect(() => {
    if (!hasArticles) {
      if (category || subcategory || timeframe || transit) {
        setFilterApplied(true);
      }
    } else {
      if (subcategory) setFilterApplied(true);
    }
  }, [category, subcategory, timeframe, hasArticles, transit]);

  return (
    <div className={`main-wrapper no-results`}>
      {isMobile ? (
        <div className="event-filters-wrapper">
          <div className="filter-header-inner__mobile">
            <h1>
              <Link to="/">
                <img src="/logo.png" alt="MTA Away" id="mta-logo__mobile" />
              </Link>
            </h1>
            <div
              id="closeButton__mobile"
              aria-label="Close"
              onKeyDown={handleFilterToggle}
              onClick={handleFilterToggle}
              data-heading-filters
            >
              close
            </div>
          </div>

          {!hasArticles && (
            <div>
              <p className="event-filters__label">Dates</p>
              <ul>
                <li
                  className={!selectedTimeFrame ? 'active' : ''}
                  key="anytime"
                  onClick={() => {
                    handleCheckedAll('allTimeFrames');
                  }}
                  onKeyDown={() => {
                    handleCheckedAll('allTimeFrames');
                  }}
                  role="presentation"
                >
                  All
                </li>
                {Object.keys(TIMEFRAMES).map(thisTimeframe => (
                  //also calculate the number of results for each timeframe
                  <li
                    className={
                      selectedTimeFrame === thisTimeframe ? 'active' : ''
                    }
                    key={thisTimeframe}
                    onClick={() => {
                      setTimeFrame(thisTimeframe);
                    }}
                    onKeyDown={() => {
                      setTimeFrame(thisTimeframe);
                    }}
                    role="presentation"
                  >
                    {TIMEFRAMES[thisTimeframe].label}
                  </li>
                ))}
              </ul>
            </div>
          )}

          <div>
            <p className="event-filters__label">Transport</p>
            <ul>
              <li
                className={!selectedTransit ? 'active' : ''}
                key="any-transit"
                onClick={() => {
                  handleCheckedAll('allTransit');
                }}
                onKeyDown={() => {
                  handleCheckedAll('allTransit');
                }}
                role="presentation"
              >
                All
              </li>
              {Object.keys(TRANSPORT).map(thisTransit => (
                <li
                  className={selectedTransit === thisTransit ? 'active' : ''}
                  key={thisTransit}
                  onClick={() => {
                    setTransit(thisTransit);
                  }}
                  onKeyDown={() => {
                    setTransit(thisTransit);
                  }}
                  role="presentation"
                >
                  {TRANSPORT[thisTransit].label}
                </li>
              ))}
            </ul>
          </div>

          {!hasArticles && (
            <div>
              <p className="event-filters__label">Location</p>
              <ul>
                <li
                  className={!selectedLocation ? 'active' : ''}
                  key="anywhere"
                  onClick={() => {
                    handleCheckedAll('allLocations');
                  }}
                  onKeyDown={() => {
                    handleCheckedAll('allLocations');
                  }}
                  role="presentation"
                >
                  All
                </li>
                {Object.keys(LOCATIONS).map(thisLocation => (
                  <li
                    className={
                      selectedLocation === thisLocation ? 'active' : ''
                    }
                    key={thisLocation}
                    onClick={() => {
                      setLocation(thisLocation);
                    }}
                    onKeyDown={() => {
                      setLocation(thisLocation);
                    }}
                    role="presentation"
                  >
                    {LOCATIONS[thisLocation].label}
                  </li>
                ))}
              </ul>
            </div>
          )}

          <div>
            <p className="event-filters__label">Category</p>
            <ul>
              <li
                className={!selectedCategory ? 'active' : ''}
                key="any-category"
                onClick={() => {
                  handleCheckedAll('allCategories');
                }}
                onKeyDown={() => {
                  handleCheckedAll('allCategories');
                }}
                role="presentation"
              >
                All
              </li>
              {Object.keys(CATEGORIES).map(thisCategory => (
                <li
                  className={selectedCategory === thisCategory ? 'active' : ''}
                  key={thisCategory}
                  onClick={() => {
                    setCategory(thisCategory);
                  }}
                  onKeyDown={() => {
                    setCategory(thisCategory);
                  }}
                  role="presentation"
                >
                  {CATEGORIES[thisCategory].label === 'Sessions At...'
                    ? 'Sessions At'
                    : CATEGORIES[thisCategory].label}
                </li>
              ))}
            </ul>
          </div>
          <a
            href="#"
            className="btn apply"
            onClick={e => {
              e.preventDefault();
              handleBuildPath();
            }}
            onKeyDown={() => {
              handleBuildPath();
            }}
          >
            Apply
          </a>
        </div>
      ) : (
        <div className="event-filters-wrapper">
          {!hasArticles && (
            <div style={{ marginBottom: '2rem' }}>
              <p
                className="event-filters__label"
                style={{ marginBottom: '1rem' }}
              >
                Dates
              </p>
              <details
                className="event-filter"
                id="timeframe-filter"
                open={isMobile}
              >
                <summary
                  className={
                    TIMEFRAMES[selectedTimeFrame] ||
                    (checkedAll.allTimeFrames && filterApplied)
                      ? 'selected'
                      : 'inactive'
                  }
                >
                  {!isMobile
                    ? TIMEFRAMES[selectedTimeFrame]?.label ||
                      (url.includes('deals') && !selectedTimeFrame
                        ? 'All Expiration Dates'
                        : 'All Dates')
                    : 'Date'}
                </summary>
                <ul
                  onClick={() => {
                    closeFilter('timeframe-filter');
                  }}
                  onKeyDown={() => {
                    closeFilter('timeframe-filter');
                  }}
                  role="presentation"
                >
                  <li
                    key="anytime"
                    onClick={() => {
                      handleCheckedAll('allTimeFrames');
                    }}
                    onKeyDown={() => {
                      handleCheckedAll('allTimeFrames');
                    }}
                    role="presentation"
                  >
                    All dates
                    {(checkedAll.allTimeFrames || !selectedTimeFrame) && (
                      <span className="material-icons">done</span>
                    )}
                  </li>
                  {Object.keys(TIMEFRAMES).map(thisTimeframe => (
                    <li
                      key={thisTimeframe}
                      onClick={() => {
                        setTimeFrame(thisTimeframe);
                      }}
                      onKeyDown={() => {
                        setTimeFrame(thisTimeframe);
                      }}
                      role="presentation"
                    >
                      {TIMEFRAMES[thisTimeframe].label}
                      {selectedTimeFrame === thisTimeframe && (
                        <span className="material-icons">done</span>
                      )}
                    </li>
                  ))}
                </ul>
              </details>
            </div>
          )}

          {!hasArticles && (
            <div className="event-filter" id="location-filter">
              <p className="event-filters__label">Locations</p>
              <div>
                <div>
                  <label className="event-filter__category-label">
                    <input
                      type="checkbox"
                      checked={checkedAll.allLocations}
                      onChange={() => handleCheckedAll('allLocations')}
                    />
                    {`Show All (${Object.values(
                      filterResults.locationCounts
                    ).reduce((a, b) => a + b, 0)})`}
                  </label>
                </div>
                {Object.keys(LOCATIONS).map(thisLocation => {
                  const location = LOCATIONS[thisLocation].label;
                  return (
                    <div key={thisLocation}>
                      <label className="event-filter__category-label">
                        <input
                          type="checkbox"
                          checked={selectedLocation === thisLocation}
                          onChange={() => {
                            setLocation(thisLocation);
                          }}
                        />
                        {LOCATIONS[thisLocation].label}
                        <span className="event-filter__result-count">
                          {!filterResults.locationCounts[location]
                            ? ` (0)`
                            : ` (${filterResults.locationCounts[location]})`}
                        </span>
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          <div className="event-filter" id="transport-filter">
            <p className="event-filters__label">Transport</p>
            <div>
              <div>
                <label className="event-filter__category-label">
                  <input
                    type="checkbox"
                    checked={checkedAll.allTransit}
                    onChange={() => handleCheckedAll('allTransit')}
                  />
                  {`Show All (${Object.values(
                    filterResults.transportCounts
                  ).reduce((a, b) => a + b, 0)})`}
                </label>
              </div>
              {Object.keys(TRANSPORT).map(thisTransit => (
                <div key={thisTransit}>
                  <input
                    type="checkbox"
                    checked={selectedTransit === thisTransit}
                    onChange={() => setTransit(thisTransit)}
                  />
                  <label className="event-filter__category-label">
                    {TRANSPORT[thisTransit].label}
                    <span className="event-filter__result-count">
                      {!filterResults.transportCounts[thisTransit]
                        ? ` (0)`
                        : ` (${filterResults.transportCounts[thisTransit]})`}
                    </span>
                  </label>
                </div>
              ))}
            </div>
          </div>
          <div className="event-filter" id="category-filter">
            <p className="event-filters__label">Category</p>
            <div>
              <div>
                <label className="event-filter__category-label">
                  <input
                    type="checkbox"
                    checked={checkedAll.allCategories}
                    onChange={() => handleCheckedAll('allCategories')}
                  />
                  {`Show All (${Object.values(
                    filterResults.categoryCounts
                  ).reduce((a, b) => a + b, 0)})`}
                </label>
              </div>
              {Object.keys(CATEGORIES).map(thisCategory => {
                const category = CATEGORIES[thisCategory].label.replace(
                  /\+/g,
                  ' + '
                );
                return (
                  <div key={thisCategory}>
                    <label className="event-filter__category-label">
                      <input
                        type="checkbox"
                        checked={selectedCategory === thisCategory}
                        onChange={() => setCategory(thisCategory)}
                      />
                      {CATEGORIES[thisCategory].label === 'Sessions At...'
                        ? 'Sessions At'
                        : CATEGORIES[thisCategory].label}
                      <span className="event-filter__result-count">
                        {!filterResults.categoryCounts[category]
                          ? ` (0)`
                          : ` (${filterResults.categoryCounts[category]})`}
                      </span>
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
      {/* {
        numResults ? (
          <div className={filterApplied ? 'results' : ''}>
            {filterApplied && !hasArticles && (
              <span>
                {resultsFor}
                {TIMEFRAMES[timeframe]?.label || 'All dates'}", "
                {CATEGORIES[category]?.label || 'All categories'}", and "
                {LOCATIONS[subcategory]?.label || 'All locations'}"
              </span>
            )}

            {filterApplied && hasArticles && (
              <span>
                {resultsFor}
                {LOCATIONS[selectedLocation]?.label || 'All locations'}"
              </span>
            )}
          </div>
        ) : null
      } */}
    </div>
  );
};

export default Filters;
