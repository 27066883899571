module.exports = {
  connecticut: {
    label: 'Connecticut',
  },
  'hudson-valley': {
    label: 'Hudson Valley',
  },
  'long-island': {
    label: 'Long Island',
  },
  'new-york-city': {
    label: 'NYC',
  },
};
