module.exports = {
  LIRR: {
    label: 'Long Island Rail Road',
  },
  Subway: {
    label: 'Subways & Buses',
  },
  'Metro-North': {
    label: 'Metro-North',
  },
};
