module.exports = {
  today: {
    label: 'Today',
    getRange: today => {
      return [today, today];
    },
  },
  tomorrow: {
    label: 'Tomorrow',
    getRange: today => {
      const startDate = new Date(today.valueOf());
      startDate.setDate(today.getDate() + 1);
      return [startDate, startDate];
    },
  },
  'this-weekend': {
    label: 'This weekend',
    getRange: today => {
      const startDate = new Date(today.valueOf());
      startDate.setDate(today.getDate() - today.getDay() + 6);
      const endDate = new Date(startDate.valueOf());
      endDate.setDate(startDate.getDate() + 1);
      return [startDate, endDate];
    },
  },
  'this-week': {
    label: 'This week',
    getRange: today => {
      const startDate = new Date(today.valueOf());
      startDate.setDate(today.getDate() - today.getDay());
      const endDate = new Date(startDate.valueOf());
      endDate.setDate(startDate.getDate() + 6);
      return [startDate, endDate];
    },
  },
  'next-week': {
    label: 'Next week',
    getRange: today => {
      const startDate = new Date(today.valueOf());
      startDate.setDate(today.getDate() - today.getDay() + 7);
      const endDate = new Date(startDate.valueOf());
      endDate.setDate(startDate.getDate() + 6);
      return [startDate, endDate];
    },
  },
  'this-month': {
    label: 'This month',
    getRange: today => {
      const startDate = new Date(today.valueOf());
      startDate.setDate(1);
      const endDate = new Date(startDate.valueOf());
      endDate.setMonth(endDate.getMonth() + 1);
      endDate.setDate(0);
      return [startDate, endDate];
    },
  },
  'next-month': {
    label: 'Next month',
    getRange: today => {
      const startDate = new Date(today.valueOf());
      startDate.setDate(1);
      startDate.setMonth(startDate.getMonth() + 1);
      const endDate = new Date(startDate.valueOf());
      endDate.setMonth(endDate.getMonth() + 1);
      endDate.setDate(0);
      return [startDate, endDate];
    },
  },
};
