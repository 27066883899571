import React from 'react';

export const NoResults = ({ message }) => {
  return (
    <li className="no-results-message">
      <p>
        <strong>No results found.</strong>
      </p>
      <p>{`${message} Please try another selection.`}</p>
    </li>
  );
};
