// Generates URLs for Category pages.
// If category or subcategory are omitted, the path part is omitted as well.
// All of these are examples of valid outputs of this function:
// /eat-drink-shop
// /eat-drink-shop/new-york-city
// /eat-drink-shop/long-island
// /eat-drink-shop/hudson-valley
// /eat-drink-shop/connecticut
// /destinations
// /destinations/new-york-city
// /destinations/long-island
// /destinations/hudson-valley
// /destinations/connecticut
// /outdoors
// /outdoors/new-york-city
// /outdoors/long-island
// /outdoors/hudson-valley
// /outdoors/connecticut
// /arts-culture
// /arts-culture/new-york-city
// /arts-culture/long-island
// /arts-culture/hudson-valley
// /arts-culture/connecticut
// /family-friendly
// /family-friendly/new-york-city
// /family-friendly/long-island
// /family-friendly/hudson-valley
// /family-friendly/connecticut
module.exports = (category, subcategory) =>
  '/' + [category, subcategory].filter(Boolean).join('/');
